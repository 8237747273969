// import { loadStripe } from "@stripe/stripe-js";
// import {
//   EmbeddedCheckoutProvider,
//   EmbeddedCheckout,
// } from "@stripe/react-stripe-js";

import { useSessionStorage } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";

import { errorOpts, processOpts, successOpts } from "../../util/toast";

// const stripePromise = loadStripe(import.meta.env.PUBLIC_STRIPE_PUBLISHABLE_KEY);

// export default function Payment() {
//   const [creditDialog, setCreditDialog] = useSessionStorage(
//     "creditDialog",
//     "hidden",
//   );

//   useEffect(() => {
//     // console.log({
//     //   creditDialog,
//     // });
//   }, [creditDialog]);

//   const [formStep, setFormStep] = useState("qty");
//   const [secret, setSecret] = useState(null);

//   const dialog = useRef(null);

//   const [disable, setDisable] = useState(false);

//   const qty = useRef(null);
//   const toastRef = useRef(null);

//   async function processCheckout() {
//     toastRef.current = toast(
//       "Please wait while we configure a secure checkout experience",
//       { ...processOpts },
//     );

//     setDisable(true);

// const { getToken, auth } = await import("../../util/firebase");

// let token = await getToken();

// let bearer = await auth.currentUser.getIdToken(true);

// const URL = `${import.meta.env.PUBLIC_BOOK_API}/user/credits`;

// const response = await fetch(URL, {
// 	method: "POST",
// 	headers: {
// 		Authorization: `Bearer ${bearer}`,
// 		"X-Firebase-AppCheck": token.token,
// 		Accept: "application/json",
// 		"Content-Type": "application/json",
// 	},
// 	body: JSON.stringify({
// 		credit: qty.current.value,
// 	}),
// });

//     if (!response.ok) {
//       const res = await response.json();
//       const { message, next_action } = res;
//       toast.update(toastRef.current, {
//         render: message,
//         ...errorOpts,
//       });

// if (next_action === "logout") {
// 	await auth.signOut();
// } else if (next_action === "subscription") {
// 	const cta = res[next_action] ?? false;
// 	if (!cta) {
// 		// Ignore
// 	} else {
// 		// Open billing
// 	}
// }

//       setDisable(false);
//       return;
//     }

// if (response.ok) {
// 	const res = await response.json();
// 	const checkout = res[res.next_action];
// 	const { secret, id } = checkout;
// 	setSecret(secret);
// 	setFormStep("payment");
// 	toast.dismiss(toastRef.current);
// 	setDisable(false);
// 	return;
// }
//   }

//   function closeHandler() {
//     // console.log("Close handler ran");
//     // setSecret(null);
//     // setFormStep("qty");
//     // setDisable(false);
//     setCreditDialog("hidden");
//     // qty.current.value = 1;
//   }

//   return (
//     <dialog
//       className="fixed bottom-0 top-0 z-50 block h-full max-h-full w-full max-w-full scale-0 overflow-hidden outline-0 transition-all duration-200 ease-linear open:scale-100 open:bg-black/40"
//       ref={dialog}
//       open={creditDialog === "visible"}
//       onClose={closeHandler}
//     >
//       <div
//         className={`relative m-0 flex max-h-full max-w-full flex-col rounded-md md:mx-auto ${formStep !== "payment" ? "top-[50%] -translate-y-[50%] md:m-[1.75rem] md:max-h-[calc(100%-3.5rem)] md:max-w-[50%]" : "max-h-[calc(100%-3.5rem)] top-[50%] -translate-y-[50%] px-2"}`}
//       >
//         <div
//           className={`flex flex-row items-center justify-between rounded-t-md border-b-[2px] border-b-theme bg-white p-2 ${formStep === "payment" ? "" : "py-4"}`}
//         >
//           <h4 className="pl-4 font-dm">Fill up your credits!</h4>
//           {formStep === "payment" && (
//             <button
//               id="close"
//               aria-label="close"
//               className="mr-3 font-dm text-[2.3rem]"
//               aria-controls=""
//               onClick={closeHandler}
//             >
//               &times;
//             </button>
//           )}
//         </div>
//         {formStep === "qty" && (
//           <div className="flex h-full min-h-fit w-full flex-col justify-center gap-3 rounded-b-md bg-white px-4 py-2">
//             <div className="flex flex-col gap-1">
//               <label
//                 htmlFor="quantity"
//                 className="ml-1 font-dm text-[0.9rem] font-bold"
//               >
//                 Credit(s) Amount *
//               </label>
//               <input
//                 type="number"
//                 min={1}
//                 max={999999}
//                 id="quantity"
//                 name="quantity"
//                 ref={qty}
//                 defaultValue={1}
//                 className="font- w-full rounded-[6px] border-2 border-solid border-light-theme p-2 py-[6px] font-dm text-base text-light-black outline-none placeholder:text-sm placeholder:text-slate-400 focus:border-2 focus:border-theme"
//                 placeholder="Enter amount of credits you want to fill"
//                 disabled={disable}
//               />
//             </div>
//             <pre className="text-wrap font-poppins text-[80%] text-gray">
//               Credits are not added in realtime. It may take a few minutes (or
//               even some hours) to process & confirm the payment. Please be
//               patient.
//             </pre>
//             <div className="flex flex-row justify-center gap-10 bg-white p-4">
//               <button
//                 className={"primary-btn min-w-[150px] scale-90"}
//                 onClick={processCheckout}
//                 disabled={disable}
//               >
//                 {disable && <Loading className="scale-50" />}
//                 Continue
//               </button>
//               <button
//                 className={"secondary-btn min-w-[150px] scale-90"}
//                 disabled={disable}
//                 onClick={closeHandler}
//               >
//                 No, Thanks
//               </button>
//             </div>
//           </div>
//         )}
//         {formStep === "payment" && (
//           <div className="w-full overflow-auto">
//             <EmbeddedCheckoutProvider
//               options={{
//                 clientSecret: secret,
//                 onComplete: (evt) => { /* console.log(evt) */ },
//               }}
//               stripe={stripePromise}
//             >
//               <EmbeddedCheckout
//                 className="w-full overflow-auto rounded-b-[6px]"
//                 key={{ secret }}
//               />
//             </EmbeddedCheckoutProvider>
//           </div>
//         )}
//       </div>
//     </dialog>
//   );
// }

export default function Payment() {

	const [creditDialog, setCreditDialog] = useSessionStorage(
		"creditDialog",
		"hidden",
	);

	const toastRef = useRef(null);

	async function processCheckout() {


		toastRef.current = toast(
			"Please wait while we configure a secure checkout experience",
			{ ...processOpts },
		);


		const { getToken, auth } = await import("../../util/firebase");

		let token = await getToken();

		let bearer = await auth.currentUser.getIdToken(true);

		const URL = `${import.meta.env.PUBLIC_BOOK_API}/user/credits`;

		const response = await fetch(URL, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${bearer}`,
				"X-Firebase-AppCheck": token.token,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				credit: 5, // Hardcoded
			}),
		});

		if (!response.ok) {
			const res = await response.json();
			const { message, next_action } = res;
			toast.update(toastRef.current, {
				render: message,
				...errorOpts,
			});


			if (next_action === "logout") {
				await auth.signOut();
			} else if (next_action === "subscription") {
				const cta = res[next_action] ?? false;
				if (!cta) {
					// Ignore
				} else {
					// Open billing
				}
			}
		}

		if (response.ok) {
			const res = await response.json();
			const checkout = res[res.next_action];

			const { link, url, id } = checkout;

			if (link) { window.location.href = link; return; }

			if (url) {
				window.location.href = link; return;
			}

			return;


		}


	}


	useEffect(() => {

		if (creditDialog === "hidden") return () => {
			setCreditDialog("hidden");
		};


		processCheckout().then( () => console.log("Process Checkout")).catch( (err) => console.error(err));

	}, [creditDialog]);


	return ((creditDialog === "visible") ? <Loading /> : <></> );
}



function Loading({ className = "" }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50px"
			height="50px"
			className={className}
			stroke="#1F2E43"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
		>
			<circle
				cx="50"
				cy="50"
				fill="none"
				stroke="currentColor"
				strokeWidth="10"
				r="35"
				strokeDasharray="164.93361431346415 56.97787143782138"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					repeatCount="indefinite"
					dur="1s"
					values="0 50 50;360 50 50"
					keyTimes="0;1"
				></animateTransform>
			</circle>
		</svg>
	);
}
