import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { signOut, signInWithCustomToken } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../util/firebase";
import { Error, Loader } from "../../components";

function decode(id) {
  const [header, body, sig] = id.split(".");

  if (!(header && body && sig)) return { body: false };

  const decodedBody = JSON.parse(window.atob(body));

  return {
    body: decodedBody["uid"],
  };
}

function navigate(path = "/") {
  window.location.replace(path ?? "/");
}

export default function AuthID() {
  const [user, loading, error] = useAuthState(auth);

  const params = useParams();

//   console.log(params);

  const [manualRedirect, setManualRedirect] = useState(
    typeof params === "object" &&
      "id" in params &&
      typeof params["id"] === "string" &&
      params["id"].length > 0
  );

  const [customToken, setCustomToken] = useState(false);

  
  useEffect(() => {
    if (customToken) return () => false;

    if(params === null) return () => false;

    if (!loading && auth && !manualRedirect) return () => navigate("/");
    // console.log(user);
    if (manualRedirect && typeof params["id"] === "string") {
      const { body } = decode(params["id"]);

      // signInWithCustomToken(auth, params["id"])
      if(!body && user) {
        
        return signOut(auth).then( () => navigate('/'))
      }

      if(body && user && body === user.id) {
        return () => navigate('/')
      }


      if(body && user && body !== user.id) {
        setCustomToken(true);
        return () => signOut(auth).then( () => signInWithCustomToken(auth, params["id"]).then( () => navigate('/') ))
      }

      if(body && !user) {
          setCustomToken(true);
          return () => signInWithCustomToken(auth, params["id"]).then( () => navigate('/'));
      }
    }
  }, [loading, auth]);

  return (
    <>
      {(loading || !user || !auth) && <Loader />}
      { error && <Error /> }
    </>
  );
}
